type PricingCardProps = {
  tokens: number;
  offPercent: number;
  url: string;
};

function PricingCard({ tokens, offPercent, url }: PricingCardProps) {
  return (
    <>
      <div
        className="rounded-xl p-1px bg-gradient-to-r md:w-48 md:h-48 max-md:w-44 max-md:h-44
from-[#FFFFFF3F] to-transparent 
hover:shadow-md hover:scale-105 transition duration-300 max-md:mb-6"
      >
        {offPercent > 0 && (
          <div className="absolute flex flex-row pl-7 -mt-2.5">
            <div
              className="w-0 h-0 
            border-t-[10px] border-t-transparent
            border-r-[10px] border-r-[#0B3146]"
            ></div>

            <div className="bg-primary w-28 h-8 rounded-b-xl pt-1">
              {offPercent}% off
            </div>

            <div
              className="w-0 h-0 
            border-t-[10px] border-t-transparent
            border-l-[10px] border-l-[#0B3146]"
            ></div>
          </div>
        )}

        <div
          className="rounded-xl bg-content3 w-full h-full text-left
    flex flex-col justify-end"
        >
          <h2 className="font-medium text-5xl pl-5">{tokens}</h2>
          <p className="text-textsecondary pb-5 pl-5">Tokens</p>
        </div>
      </div>
    </>
  );
}

export default PricingCard;
