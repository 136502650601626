import { useEffect, useState } from "react";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";
import { useTranslation } from "react-i18next";
import { Euro } from "react-huge-icons/solid";
import { ConstantInfo } from "../../core/constantInfo";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import PaypalService from "../../api/paypalService";
import { useNavigate } from "react-router-dom";
import getConfig from "../../config/configUtil";

const conf = getConfig;

interface ModalProps {}

const Modal: React.FC<ModalProps> = () => {
  const isOpen = useModalRefillBalanceStore((state) => state.modalOpen);
  const { t, i18n } = useTranslation();
  const [isMouseOutside, setIsMouseOutside] = useState(false);
  const [selectedValue, setSelectedValue] = useState(10.0);
  const [selectedEuros, setSelectedEuros] = useState(10.0);
  const navigate = useNavigate();
  const currentLanguageCode = i18n.language;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const closeModal = () => {
    useModalRefillBalanceStore.setState({ modalOpen: false });
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains("bg-black")) {
      setIsMouseOutside(true);
    } else {
      setIsMouseOutside(false);
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isMouseOutside) {
      const target = event.target as HTMLDivElement;
      if (target.classList.contains("bg-black")) {
        closeModal();
      }
    }
  };

  const createOrder = async () => {
    const paypalService = new PaypalService();
    const response = await paypalService.createOrder(selectedEuros, selectedValue);

    return response.id;
  };

  const onApprove = async (data: any) => {
    const paypalService = new PaypalService();
    await paypalService.approve(data.orderID);

    navigate(0);
  };

  const getDiscount = (tokens: number): number => {
    let discount = 0;

    if (tokens >= 500) {
      discount = 0.2; // 20% скидка
    } else if (tokens >= 300) {
      discount = 0.15; // 15% скидка
    } else if (tokens >= 200) {
      discount = 0.1; // 10% скидка
    } else if (tokens >= 100) {
      discount = 0.05; // 5% скидка
    }

    return discount;
  };

  const calculatePriceInEuro = (tokens: number): number => {
    const pricePerToken = 1; // 1 токен = 1 евро
    const discount = getDiscount(tokens);

    const totalPrice = tokens * pricePerToken * (1 - discount);
    return Math.ceil(totalPrice); // Округляем в большую сторону
  };

  const setValues = (value: number) => {
    const euros = calculatePriceInEuro(value);
    setSelectedEuros(euros);
    setSelectedValue(value);
  };

  const ButtonWrapper = () => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <div className="h-fit">
        {isPending ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          </div>
        ) : (
          <>
            <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              forceReRender={[selectedEuros]}
              
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-60"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="relative bg-content2 p-8 rounded-lg w-126 h-fit text-center flex flex-col justify-between animate-fade-in">
        <button
          className="absolute top-4 right-4 bg-transparent text-textsecondary text-3xl"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-white gap-4 text-2xl font-bold">
            <div className="flex flex-col items-center justify-center rounded-full w-32 h-32 border-4 border-gray-600">
              <span className="text-6xl text-primary">1</span>
              <span className="text-sm">TOKEN</span>
            </div>

            <div className="-ml-12 z-10">
              <span
                className="text-primary text-6xl font-extrabold"
                style={{
                  WebkitTextStroke: "2px black",
                  WebkitTextFillColor: "currentColor",
                }}
              >
                =
              </span>
            </div>

            <div className="-ml-10 flex items-center justify-center rounded-full w-32 h-32 border-4 border-primary bg-content2 relative">
              <span className="text-7xl text-primary font-bold -ml-3">€</span>

              <div className="absolute top-1/2 right-6 transform -translate-y-1/2">
                <span className="text-5xl font-semibold">1</span>
                <span
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs opacity-80 font-bold bg-content2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  EURO
                </span>
              </div>
            </div>
          </div>
        </div>

        {currentLanguageCode === "ru" && (
          <>
            <ul>
              <li>
                <p className="text-textsecondary text-sm">
                  {t("For customers from Russia")}
                </p>
                <p className="text-textsecondary text-sm">
                  {t("Payment with bank")}
                </p>
              </li>
            </ul>

            <p className="text-textsecondary pt-3">
              {t(
                "To receive the payment details, please contact our WhatsApp support service"
              )}{" "}
              <a href={ConstantInfo.phone} className="text-white">
                {ConstantInfo.phoneView}
              </a>
              .
            </p>
          </>
        )}

        <p className="text-textsecondary pt-7 text-sm">
          {t("Automatic payment via PayPal")}
        </p>
        <p className="text-textsecondary text-sm">
          {t("payment using a debit card")}
        </p>

        <div className="flex items-center justify-center">
          <div className="mt-4 flex items-center justify-center w-96 h-28 rounded-lg border border-textsecondary">
            <p className="text-primary">Tokens:</p>

            <div className="relative flex items-center max-w-[9rem]">
              <button
                type="button"
                id="decrement-button"
                data-input-counter-decrement="quantity-input"
                className="bg-tablebg hover:bg-gray-600 border-tableline border 
                rounded-s-lg p-3 h-11"
                onClick={() =>
                  selectedValue > 10 ? setValues(selectedValue - 1) : null
                }
              >
                <svg
                  className="w-3 h-3 text-textsecondary"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                id="quantity-input"
                data-input-counter
                aria-describedby="helper-text-explanation"
                className=" border-x-0 h-11 text-center text-2xl
                border-primary block w-full py-2.5 bg-tablebg dark:border-tableline 
                text-primary"
                placeholder="10"
                value={selectedValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setValues(Number(value));
                }}
                onBlur={(e) => {
                  let value = Number(e.target.value);
                  if (value < 10) value = 10;
                  if (value > 500) value = 500;
                  setValues(value);
                }}
                required
              />
              <button
                type="button"
                id="increment-button"
                data-input-counter-increment="quantity-input"
                className="bg-tablebg hover:bg-gray-600 border-tableline border 
                rounded-e-lg p-3 h-11"
                onClick={() =>
                  selectedValue < 500 ? setValues(selectedValue + 1) : null
                }
              >
                <svg
                  className="w-3 h-3 text-textsecondary"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>
            <p className="ml-2 text-primary">=</p>
            <Euro className="w-12 h-12 ml-2 text-primary" />
            <div>
              <p className="text-primary text-4xl">{selectedEuros}</p>
              {selectedValue >= 100 && (
                <p className="text-xs align-text-top text-textsecondary">
                  {getDiscount(selectedValue) * 100}% off
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4 h-fit">
          <PayPalScriptProvider
            options={{
              clientId: conf.PAYPALCLIENTID,
              currency: "EUR",
              intent: "capture",

            }}
          >
            <ButtonWrapper />
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
};

export default Modal;
