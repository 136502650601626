import axios, { AxiosResponse } from "axios";
import getConfig from "../config/configUtil";

const conf = getConfig;

export interface CreateOrderResult {
  id: string;
}

class PaypalService {
  async createOrder(euro: number, value: number): Promise<CreateOrderResult> {
    try {
      const response: AxiosResponse = await axios.post(conf.BACKEND + "paypal/create-order", {
        euro,
        value,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to create order");
    }
  }

  async approve(orderID: string): Promise<CreateOrderResult> {
    try {
      const response: AxiosResponse = await axios.post(conf.BACKEND + "paypal/confirm-order", {
        orderID,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to confirm order");
    }
  }
}

export default PaypalService;
